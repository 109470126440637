<template>
  <div class="wrap">
    <h3>隐私政策</h3>
    <p class="p1">
      本隐私政策旨在向您说明我们如何收集、使用、分享以及保护您的个人信息及其它相关信息， 请您务必认真阅读。当您向我们提供您的个人信息或开始使用我们的产品或服务时，即表示 您已知悉并同意我们在本隐私政策中所述内容。希望您仔细阅读隐私政策，详细了解我们对 信息的收集、使用方式，以便您更好地了解我们的服务并作出适当的选择。我们可能会为了 向您提供特定服务的目的，收集和使用您的个人敏感信息。个人敏感信息是指一旦泄漏、非 法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心受到损害或歧视性待遇等 的个人信息，种族、宗教、政治立场、个人财产信息、个人健康生理信息、个人身份信息和 网络身份标识信息等可能构成个人敏感信息。我们会在以下描述中将个人敏感信息以下划线 形式标记，提醒您注意。在使用过程中，请谨慎考虑是否向我们提供这些信息。我们将尽最 大努力保护好您的个人信息。
      我们可能会对隐私政策进行修改，如有重大更改（例如业务功能、使用目的、个人信息保护 相关负责人联系方式等变更），我们将在新版隐私政策正式生效前，通过页面通知的方式在 兰州市残疾人就业创业网络服务平台网站和兰州市残疾人就业创业网络服务平台 App 上公布新版隐私政策，方便您了解具体的变化并向我们提出建议。如果您继续使用我们的服务即被视为对新版隐私政策的接受，如果您不接受修改后的条款，您可以注销账号。 本隐私政策版本的更新日期为2020年 8 月 10 日，并将于 2020年8 月 15日正式生效。
    </p>
    <ul>
      <li>
        <p>一、我们如何收集您的信息</p>
        <h5>1. 注册账号</h5>您可以通过兰州市残疾人就业创业网络服务平台网站、兰州市残疾人就业创业网络服务平台App、微信公众号和微信小程序，或在我们客服的帮助下线下注册兰州市残疾人就业创业网络服务平台（以下简称“平台”）账号并享受平台的服务。您也可以在不注册账号或 不登录的情形下浏览平台网站，但您注册之后可以享受更全面和优质的服务 （即个人寻找职业和雇主寻找雇员）。
        在您注册账号时，我们需要收集您的电话号码或个人邮箱，我们将通过发送短信验 证码或邮件的方式来验证您的身份是否有效。您也可以使用微博、微信和 QQ 的第 三方账号关联登录，为保存登录信息并在不同设备登录时能同步数据，我们将需要 您授予我们从第三方账号获得某些信息的权限，如使用者第三方关联登录的唯一标 识、头像、昵称。有关您在这些第三方账号中所有的隐私控制、选项及具体的隐私 规定，请参阅他们的隐私政策。如果您在客服的协助下线下完成账户注册，我们将 在您第一次登录时提供隐私政策并获取您的同意。
        当您代表您的雇主注册并使用企业招聘账号时，我们需要通过收集您的姓名、手机 号码、身份证号和手持身份证的照片进行招聘身份认证，以最大限度地保证招聘信息的真实和准确。
        <div>您在登录后，修改个人头像时，我们需要得到您的授权后，访问您的相机或相册，以便于您顺利更换头像。</div>
        <h5>2. 创建简历</h5>为实现平台的人力资源服务，您可以注册账号并创建您的微简历。微简历中需要您填写真实姓名、性别、手机号码、个人邮箱、学历、毕业学校、专业、期望工 作地区、期望工作性质和现居住城市。此外，您可以自行选择对简历进行完善，在个人信息类别中，我们可能收集您的出生日期、民族（选填）、健康状况（选填）、 婚姻状况（选填）、政治面貌（选填）、籍贯、入学前户口所在地、毕业时间（选 填）、身高（选填）和体重（选填）；在教育经历类别中，您可以选择添加您过去的教育经历；在求职意向中，我们可能收集您的期望从事行业、期望从事职业、目前状况、期望月薪以及到岗时间（均选填）；在工作经历类别中，我们可能收集您 的工作过的公司名称、职位名称、所属行业、所属职类、月薪、工作内容描述；此 外，您还可以自行选择填写您的项目经验、奖励获得、获奖证书、语言能力、培训 经历、自我评价、技能爱好、家庭关系，并上传相应附件。
        <h5>3. 使用我们的服务</h5>当您使用我们的服务进行简历投递时，我们会收集您的投递记录，方便您能查看投 递职位的进展及面试状态等。
        当您使用问答社区时，我们将收集并对外展示您的昵称、学校、职位信息，您也可以选择在社区中匿名发布信息；如果您需要在问答社区上传新头像或在您发布 的信息当中添加图片，我们需要根据您的授权访问您的图片库，您有权选择“不允 许”此项访问且不会影响您使用基础功能；您需要了解您通过问答社区发表的信 息为公开的信息且对第三方可见。
        为了方便您查看企业招聘职位的地理位置信息或离您最近的职位信息，或向附近的企业查询附近的求职者，您可以授权我们获得您的地理位置信息。
        当您代表您的雇主使用我们的服务时，您可以选择授权我们获取您的通讯录信息， 您可以邀请其他招聘单位的负责人进行认证。您在使用企业招聘账号时，您的雇主可以查阅您对该账号的使用和管理，包括职位管理、简历管理、消息管理等。
        <h5>4. 参与活动</h5>当您参加我们举办的专题活动时，除用户的基本信息外，我们还可能会收集您的个人照片及其所发 布的动态、言论、文章等。您在使用上述社交互动服务时，您应当知晓您提交的任 何信息，包括您的姓名、邮箱地址，均有可能被他人获知，请您谨慎考虑披露您的 相关个人敏感信息。
        <h5>5 问卷调研</h5>为了向您提供更好的服务和网络使用环境，我们可能会发起问卷调研以了解您的使 用偏好、相关意见，例如您感兴趣的资料、喜欢的网站或不喜欢的网站、感兴趣的职业。您可以自主选择是否参加问卷调研，调研结果将仅供统计使用。
        <h5>6. 反馈</h5>我们可以通过平台网站接收您所提供的反馈信息。通过此类链接收到的反馈信 息的全部相关权利（包括但不限于复制、转引该等信息的权利）属于我们所有。由于反馈信息由您主动提供，因此视为您已同意我们获取并在合法范围内使用此类反 馈信息。例如，我们可以利用此类反馈信息，制作市场宣传活动的内容。我们可能通过您所提供的联系方式与您联系并进一步获得相关反馈信息。
        <h5>7. Cookies</h5>当您使用我们的 App 或网站时，我们可能通过 Cookies 或同类技术收集您的设备型号，操作系统、唯一设备标识符、登录 IP 地址信息，以及缓存您的浏览信息、点击信息，以查看您的网络环境。通过 Cookies，我们可以在您访问网站时识别您的身份，并不断优化网站的用户友好程度，并根据您的需要对网站做出调整。您也可以更改浏览器的设置，使浏览器不接受我们网站的Cookies，但这样可能会影响您对网站的部分功能的使用。
        <h5>8. 我们从其他来源获取的信息</h5>我们可能从其他合法来源获取您的个人信息及其他信息，如通过公开数据库、社交媒体、第三方服务供应商及其他合作伙伴，我们将努力核查信息来源的合法性，并保护您的个人信息。我们不会将改善服务质量、提升用户体验作为单独的目的收集 您的个人信息。
      </li>
      <li>
        <p>二、 我们如何使用您的信息</p>我们从上述渠道收集的您的个人信息，可能用作下列用途：
        1. 提供产品或服务
        为实现我们最基本的人力资源服务，即帮助个人寻找职业，帮助雇主寻找雇员，我们需要收集并使用您的求职信息，以帮助个人或雇主查看对方的信息，并达成雇佣 的合意。
        当您填写微简历之后可将简历向特定企业投递，我们将会把您的简历信息提供 给您所投递的招聘单位，供其背景审查或职业选拔；对您感兴趣的招聘单位将获得您的联系方式和邮箱地址或对您发出面试邀请； 
        当您把简历设置为“公开”时，招聘单位可主动搜索您的简历，以增加您的求职机会，我们不会在搜索结果中公开您的姓名和联系方式；对您感兴趣的招聘单位可以与您约聊、对您发送投递简历邀请或下载您的简历。
        我们将实时回应您的询问及请求，由客服人员联络您并解决您的问题；我们也将通过问卷调查等方式获取您的职业意向和具体需求；此外，我们会向您推送消息帮助您了解最新求职进展和相关求职机会。对于邮件推送信息，您可以点击邮件中的“取消订阅”或“这里退订”按钮以实施邮件退订，不同版本的邮箱的按钮名称可能略有差异。
        3. 改善并优化服务
        我们可能将收集到的信息用于了解您如何以及在何种网络环境下使用我们的服务，从而向您提供更好的服务并针对性地回应您的需求。例如了解您的使用偏好，感兴趣的职业，以提升服务质量并优化服务的功能。
        4 分析和研究目的
        在某些情况下，我们可能会使用到您的个人信息以及经 过去标识化和匿名化的信息。
        5 法律要求及法律程序
        我们可能会将您的信息用于法律法规、法院命令或其他法律程序、或有权政府机关要求的用途，包括协助公安机关调查相关诈骗案件或维持网络社区的言论秩序。
      </li>
      <li>
        三、 我们如何共享您的信息
        我们将在以下情形中向第三方分享或提供您的个人信息：
        <h5>1. 用户事先授权</h5>在获得您事先授权的前提下，我们可能会将您的信息分享给我们的关联公司、招聘 单位、第三方服务提供商或合作伙伴，如我们根据您的授权将 App 的相关内容分 享给您授权的微信、微博等社交媒体服务提供商。由于我们提供的是招聘服务，查阅您的简历的招聘单位数量直接影响了您的求职机会，您可以直接将简历投递给招聘单位，也可以选择在简历|隐私设置中设定为“公开”，使招聘单位能根据需求 搜索到您的简历，当您将其设置为“保密”后，您的简历则无法被招聘单位搜索。
        <h5>2. 本隐私政策所述目的</h5>为了向您提供服务或实现上述信息使用的目的，我们可能将您的个人信息分享给我们的关联公司、招聘单位、合作伙伴或第三方服务提供商，第三方服务提供商可能将您的个人信息存储于其服务器上。例如，在您投递简历时，将您的简历信息分享给招聘单位，或者帮助我们完成网络维护、审计、安全检测、分析、营销和开发的合作伙伴在必要范围内获取您的个人信息。第三方通过嵌入代码、插件等方式获取个人信息的前提是获得您的明确授权。
        <h5>3. 法律法规或政府要求</h5>根据法律法规、法院命令或其他法律程序、或有权政府机关的要求，向法院或政府机关提供您的信息。例如，出现网络诈骗行为时公安机关对嫌疑人的相关信息的调取。
        <h5>4. 紧急危险</h5>为免除我们及员工、服务的用户或公众在生命、身体或财产方面的紧急危险时，我们可能在法律法规要求或允许的范围内分享您的信息。
        <h5>5. 第三方技术服务</h5>为提供和优化我们的服务，我们的 App 中内嵌了第三方的 SDK，包括但不限于新浪微博、阿里云和百度地图。这些第三方 SDK 在配合我们向您提供更全面的服务的同时，可能会收集您的个人信息，上述所有信息将去标识化传输。我们将尽可能控制并保护个人信息的收集，并将通过弹窗的方式告知您，相关隐私实践请详见该等第三方的隐私政策。
        Push SDK（小米、华为、魅族、个推、vivo、oppo） 为了向您推送系统通知或重要消息，在您授权同意后，Push SDK 可能获取访问您的手机型号、系统类型、系统版本、设备手机屏幕尺寸、参数权限，如果 您不希望收到这些通知或信息，可以在终端设备设置中关闭消息通知。
      </li>
      <li>
        <p>四、 第三方网站</p>我们的网站中可能包含第三方运营网站的链接。对于与平台网站链接的或平台 网站包含的第三方运营网站及第三方网络广告公司的相关产品或服务，并受他们的隐私 政策约束。平台网站所包含的链接不代表我们认可或负责该第三方运营网站的隐私 政策。此外，我们的网站中还存在网络110 报警服务，以响应您的紧急安全请求。
      </li>
      <li>
        <p>五、 我们如何存储您的信息</p>除法律法规另有规定或执法机关有特殊要求，我们会在法律规定范围内的最短期限及达成本隐私政策所述目的所需的期限内保留您的个人信息，超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。
      </li>
      <li>
        <p>六、 您的权利及选择</p>根据适用的法律法规，我们将采取适当的技术手段，保证您访问、更新、更正或删除您的个人信息的权利。如有法律法规的要求导致我们无法满足您的请求的，我们将向您提供合理的解释。
        访问权您有权访问您的平台网站或 App 的账户信息、投递记录、订阅信息等信息。您登录帐号后，即可访问我们保留的您的个人信息，如您希望获取更为详尽的清单，您可以通过联系我们提出您的具体要求。
        更正权 您在账户内可修改您的原有个人信息的设置，包括但不限于个人简历、订阅信息、收藏 的职位和关注的公司。当您发现我们处理的关于您的个人信息有错误且您无法在账户内 自行修改时，您有权通过“联系我们”要求我们对错误或不完整的信息做出更正或更新。
        删除权 当您不再使用我们的产品或服务且要求删除您的个人信息时，或我们违反法律法规或与 您的约定收集、使用、与他人分享您的个人信息时，您有权要求删除您的个人信息。您可以在简历设置中自主修改、删除您的个人信息，但出于监管机关打击犯罪、维护国家 安全的要求，我们可能会对您的通讯信息进行记录和保存。为了防止错误操作导致信息 丢失，当您删除您最后一份简历时，您需要联系客服进行处理，当我们决定响应您的删除请求时，我们将尽快从平台服务中删除信息，使您的信息不可见。
        撤销权 每个业务功能需要一些基本的个人信息才能得以完成。对于非必要收集的个人信息，您 可以随时给予或收回您的授权同意。
        您已经投递的简历或被企业搜索已下载过的您的简历，以及您与他人分享的信息在注销账号或删除信息后，我们可能不会立即删除相应的信息，因为我们无法删除他人从我们的服务中复制的信息。
        注销权 您可通过联系客服的方式，随时注销此前注册的账户，我们将尽快删除该账号。账户注 销可能会导致清除您的所有用户数据和账户信息且不可恢复；但在特定情形下，如合理 必要地履行我们的法律义务、解决争议、防止欺诈与滥用，我们将在使用者账号注销后 保留不可识别个人的信息。
        您可根据以下“联系我们”部分所列的方式与我们联络，以行使上述权利；或者对于通过平台网站和平台 App 收集的个人信息，您可通过平台网站或App中的帮助与反馈功能提交相关权利要求。当您请求行使上述权利，或进行其他申诉时，原则上我们将于 15 天内回复处理意见或结果。
      </li>
      <li>
        <p>七、 数据跨境转移</p>您的个人信息将只存储在位于中国的服务器上，您的信息将不会被我们主动传输到境外。 您需要知晓，通过平台服务开展招聘的企业包含大量的跨国公司和境外集团，上述公司可能在境外使用平台网站、App、微信公众号和小程序，或将您的简历传输境外总部，具体实践请见各招聘企业的隐私政策。
      </li>
      <li>
        <p>八、 安全事件通知</p>我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照适用法律及法规向有关主管部门报告。
        在发生安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。
      </li>
      <li>
        <p>九、 未成年人保护</p>除了适用法律要求外，我们不会在明知的情况下收集未成年人（未满十八周岁）的个人信息。我们将在简历输入的功能中对年龄进行限定，不再提供十八周岁以下的年龄区间选项。十六周岁至十八周岁的未成年人使用平台服务，应当由监护人注册账户和填写相关信息。如未经其监护人同意，未成年人请勿向我们提供个人信息。
      </li>
      <li>
        <p>十、 联系我们</p>
        平台名称：<span >兰州市残疾人就业创业网络服务平台</span>
        联系方式：<span> 010-68060691</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
@import "~assets/css/total.less";
</style>
